/* eslint-disable indent *//* eslint-disable no-unused-vars *//* eslint-disable linebreak-style */
import React, { useEffect, useState } from "react";
import UserList from "../Components/userList";
import ModalComponent from "../Components/modal";
import { db, auth } from "../utils/firebase";
import { collection, query, where, addDoc, onSnapshot, getDocs } from "firebase/firestore";
import { createUserWithEmailAndPassword, signOut, updateProfile, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const UserPanel = () => {
    const navigate = useNavigate();

    const [addUser, setAddUser] = useState(false);
    const [userArr, setUserArr] = useState([]);
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [type, setType] = useState("");
    const [percentage, setPercentage] = useState("");
    const [password, setPassword] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [email, setEmail] = useState(null);
    const [masterEmail, setMasterEmail] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [statusFilter, setStatusFilter] = useState("active"); // State for status filter

    const handleInputChange = (e, setState) => {
        setState(e.target.value);
    };

    useEffect(() => {
        const fetchData = () => {
            // Create a query to filter users where superadmin is equal to the current user's email
            const usersRef = query(collection(db, "users"), where("superadmin", "==", auth.currentUser.email));
            const userProfileRef = query(collection(db, "userProfile"), where("superadmin", "==", auth.currentUser.email));

            onSnapshot(usersRef, (usersSnapshot) => {
                const usersDataArray = usersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                onSnapshot(userProfileRef, (userProfileSnapshot) => {
                    const userProfileDataArray = userProfileSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    // Merge the data from both collections
                    const mergedDataArray = [...usersDataArray, ...userProfileDataArray];

                    // Filter user list based on search query and status filter
                    const filteredUsers = mergedDataArray.filter(user =>
                        (statusFilter === "all" || user.status === statusFilter) &&
                        ((user.displayName && user.displayName.toLowerCase().includes(searchQuery.toLowerCase())) ||
                            (user.emailId && user.emailId.toLowerCase().includes(searchQuery.toLowerCase())) ||
                            (user.phoneNumber && user.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase())))
                    );

                    setUserArr(filteredUsers);
                    console.log(filteredUsers);
                });
            });
        };

        fetchData();
    }, [searchQuery, statusFilter]);

    const addUserFunction = async () => {
        // Validate that all fields are filled except percentage
        if (!name || !email || !password || !type) {
            alert("All fields except percentage must be filled.");
            return;
        }
        try {
            // Check if email already exists
            const usernameQuery = query(collection(db, "userProfile"), where("emailID", "==", email));
            const usernameQuerySnapshot = await getDocs(usernameQuery);
            if (!usernameQuerySnapshot.empty) {
                // Email already exists, cancel the operation
                console.log("Email already exists. Operation cancelled.");
                return;
            }

            // Get the current user's credentials to re-authenticate after signout
            const currentEmail = localStorage.getItem("email");
            const currentPassword = localStorage.getItem("password");
            const formattedEmail = `${email.toLowerCase()}@gmail.com`;

            // Sign up the new user
            const userCredential = await createUserWithEmailAndPassword(auth, formattedEmail, password);
            const user = userCredential.user;

            // Update the display name of the new user
            await updateProfile(user, { displayName: name });

            // If user signup is successful, add the new user to Firestore
            await addDoc(collection(db, "userProfile"), {
                uid: user.uid,
                displayName: name,
                emailId: formattedEmail,
                type: type,
                percentage: percentage || 0,  // Default percentage to 0 if not provided
                phoneNumber: phoneNumber,
                wallet: 0,
                accessCode: "",
                fundAddHistory: [],
                fundWithdrawHistory: [],
                betLog: [],
                winLog: [],
                masterEmail: `${masterEmail}@gmail.com`,
                adminEmail: `${adminEmail}@gmail.com`,
                passwordStatus: false,
                status: "active",
                superadmin: currentEmail,
                password: password
            });
            console.log("User added successfully");

            // Log out the newly created user
            await signOut(auth);

            // Re-authenticate the previous user
            await signInWithEmailAndPassword(auth, currentEmail, currentPassword);

            // Clear input fields after adding user
            setName("");
            setEmail("");
            setPassword("");
            setType("");
            setPercentage("");
            setPhoneNumber("");
            setMasterEmail("");
            setAdminEmail("");

            // Close the modal
            setAddUser(false);

            // Refresh user list
            setSearchQuery("");

            // Navigate to /users after successful operation
            navigate("users");
        } catch (error) {
            console.error("Error adding user: ", error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
    };

    return (
        <div>
            {addUser &&
                <ModalComponent closeModal={() => setAddUser(false)}>
                    <div className='addUserModal' style={{ flexDirection: "column", display: "flex", padding: "10px" }}>
                        <p className='addUserModalHeading'>Add User</p>
                        <div className="info-Inputs-user">
                            <label htmlFor="name">
                                Name
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='Enter Name'
                                    value={name}
                                    onChange={(e) => handleInputChange(e, setName)}
                                />
                            </label>
                            <label htmlFor="emailid">
                                Username
                                <input
                                    type="text"
                                    id="emailid"
                                    name="emailid"
                                    placeholder='Enter Username'
                                    value={email}
                                    onChange={(e) => handleInputChange(e, setEmail)}
                                />
                            </label>
                            <label htmlFor="phoneNumber">
                                Phone Number
                                <input
                                    type="number"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder='Enter Phone Number'
                                    value={phoneNumber}
                                    onChange={(e) => handleInputChange(e, setPhoneNumber)}
                                />
                            </label>
                            <label htmlFor="type">
                                Type
                                <select
                                    id="type"
                                    name="type"
                                    value={type}
                                    onChange={(e) => handleInputChange(e, setType)}
                                >
                                    <option value="">Select Type</option>
                                    <option value="Admin">Admin</option>
                                    <option value="User">User</option>
                                    <option value="Master">Master</option>
                                </select>
                            </label>
                            <label htmlFor="percentage">
                                Percentage
                                <input
                                    type="text"
                                    id="percentage"
                                    name="percentage"
                                    placeholder='Enter Percentage'
                                    value={percentage}
                                    onChange={(e) => handleInputChange(e, setPercentage)}
                                />
                            </label>
                            <label htmlFor="password">
                                Password
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder='Enter Password'
                                    value={password}
                                    onChange={(e) => handleInputChange(e, setPassword)}
                                />
                            </label>
                            <button className='addUserSubmit' onClick={addUserFunction}>Add User</button>
                        </div>
                    </div>
                </ModalComponent>
            }
            <div className="toppart-diff">
                <div className="section--29485">
                    <input type="text" placeholder='Search by Name' onChange={handleSearchChange} />
                    <input type="text" placeholder='Search by Username' onChange={handleSearchChange} />
                    <input type="text" placeholder='Search by Phone Number' onChange={handleSearchChange} />
                    <select onChange={handleStatusFilterChange}>
                        <option value="">Select An Option</option>
                        <option value="all">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </div>
                <div className="section-553872">
                    <button id='search-userPanel' onClick={() => setSearchQuery(searchQuery)}>Search</button>
                    <button id='addUser-usePanel' onClick={() => setAddUser(true)}>Add User</button>
                </div>
            </div>
            <div className="v-Heading-UserList">
                <p>User List</p>
            </div>
            <UserList users={userArr} />
        </div>
    );
};

export default UserPanel;
