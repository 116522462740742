/* eslint-disable no-unused-vars *//* eslint-disable react/prop-types *//* eslint-disable no-undef *//* eslint-disable linebreak-style *//* eslint-disable indent */
/* eslint-disable quotes */
import React, { useState, useEffect } from "react";
import "./table.css";
import ModalComponent from "./modal";
import { db, functions, auth } from "../utils/firebase";
import { doc, updateDoc, getDoc, query, where, collection, getDocs } from "firebase/firestore";
import AdditionalInnerUser from "./additionalInnerUser";
import { httpsCallable } from 'firebase/functions';

const UserList = ({ users }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserInfo, setSelectedUserInfo] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [addAmount, setAddAmount] = useState();
    const [confirmAdd, setConfirmAdd] = useState();

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const resetPassword = httpsCallable(functions, 'resetPassword');

    const openModal = (user) => {
        setSelectedUser(user);
    };

    const closeModal = () => {
        setSelectedUser(null);
    };

    const handleInputChange = (e, key) => {
        const newValue = e.target.value;
        setSelectedUser(prevState => ({
            ...prevState,
            [key]: newValue
        }));
    };

    const handleEditUser = async () => {
        try {
            const userDocRef = doc(db, "userProfile", selectedUser.id);
            await updateDoc(userDocRef, selectedUser);

            if (selectedUser.password) {
                await resetPassword({ uid: selectedUser.uid, newPassword: selectedUser.password });
            }

            console.log("User updated successfully:", selectedUser);
            closeModal();
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    const handleUsernameClick = (username, type) => {
        setSelectedUserInfo({ username, type });
    };

    const handleAddButtonClick = async (user) => {
        try {
            // Check if user is selected
            if (!user) {
                throw new Error("No user selected to add funds.");
            }

            // Fetch the current user's profile using emailId
            const q = query(
                collection(db, "userProfile"),
                where("emailId", "==", auth.currentUser.email)
            );
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                throw new Error("No profile found for the current user.");
            }

            const ownProfileDoc = querySnapshot.docs[0]; // Assuming the query returns one document
            const ownProfileData = ownProfileDoc.data();
            console.log(ownProfileData);
            const ownWallet = parseFloat(ownProfileData.wallet);

            // Prompt for amount to add
            const amountToAdd = prompt("Enter the amount to add to wallet:");
            let amount = 0;

            if (amountToAdd !== null && !isNaN(parseFloat(amountToAdd))) {
                amount = parseFloat(amountToAdd);

                // Check if the entered amount is less than the wallet balance
                if (amount > ownWallet) {
                    alert("Amount entered exceeds your wallet balance. Operation aborted.");
                    return;
                }
            } else {
                amount = 0; // Default to 0 if NaN
            }

            // Fetch the selected user's profile
            const userDocRef = doc(db, "userProfile", user.id);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                throw new Error("Selected user's profile not found.");
            }

            const userData = userDoc.data();
            const currentBalance = parseFloat(userData.wallet);
            const newBalance = currentBalance + amount;

            const walletBefore = currentBalance;
            const walletAfter = newBalance;

            const fundAddHistoryEntry = {
                walletBefore,
                walletAfter,
                amount,
                date: new Date().toLocaleDateString(),
                time: new Date().toLocaleTimeString(),
                status: "approved"
            };

            const updatedFundAddHistory = [
                ...(userData.fundAddHistory || []),
                fundAddHistoryEntry
            ];

            // Update the selected user's wallet balance and history
            await updateDoc(userDocRef, {
                wallet: newBalance,
                fundAddHistory: updatedFundAddHistory
            });

            // Deduct the amount from the current user's wallet
            const updatedOwnWallet = ownWallet - amount;

            // Update the current user's wallet balance
            await updateDoc(ownProfileDoc.ref, {
                wallet: updatedOwnWallet
            });

            console.log(`Successfully added ${amount} to ${user.emailId}'s wallet and deducted from own wallet.`);
            alert(`Successfully added ${amount} to ${user.emailId}'s wallet and deducted from your wallet.`);
        } catch (error) {
            console.error("Error adding funds:", error);
            alert("Failed to add funds. Please try again.");
        } finally {
            setAddAmount("");
            setConfirmAdd(false);
        }
    };



    const handleDeductButtonClick = async (user) => {
        try {
            // Check if user is selected
            if (!user) {
                throw new Error("No user selected to deduct funds.");
            }

            // Prompt for amount to deduct
            const amountToDeduct = prompt("Enter the amount to deduct from wallet:");
            let amount = 0;

            if (amountToDeduct !== null && !isNaN(parseFloat(amountToDeduct))) {
                amount = parseFloat(amountToDeduct);
            } else {
                amount = 0; // Default to 0 if NaN or cancelled
            }

            // Fetch the selected user's profile
            const userDocRef = doc(db, "userProfile", user.id);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                throw new Error("Selected user's profile not found.");
            }

            const userData = userDoc.data();
            const currentBalance = parseFloat(userData.wallet);
            const newBalance = currentBalance - amount; // Deduct the amount

            // Check if the user's wallet has sufficient funds
            if (newBalance < 0) {
                alert("Insufficient funds. Deduction not possible.");
                return; // Exit if funds are insufficient
            }

            // Fetch the current user's profile using emailId
            const q = query(
                collection(db, "userProfile"),
                where("emailId", "==", auth.currentUser.email)
            );
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                throw new Error("No profile found for the current user.");
            }

            const ownProfileDoc = querySnapshot.docs[0]; // Assuming the query returns one document
            const ownProfileData = ownProfileDoc.data();
            const ownWallet = parseFloat(ownProfileData.wallet);

            const updatedOwnWallet = ownWallet + amount; // Add the deducted amount to your own wallet

            // History entry for the deduction
            const walletBefore = currentBalance;
            const walletAfter = newBalance;

            const fundWithdrawHistoryEntry = {
                walletBefore,
                walletAfter,
                amount, // Amount is now either a valid number or 0
                date: new Date().toLocaleDateString(),
                time: new Date().toLocaleTimeString(),
                status: "deducted"
            };

            const updatedFundWithdrawHistory = [
                ...(userData.fundWithdrawHistory || []),
                fundWithdrawHistoryEntry
            ];

            // Update the selected user's wallet balance and history
            await updateDoc(userDocRef, {
                wallet: newBalance,
                fundWithdrawHistory: updatedFundWithdrawHistory
            });

            // Update the current user's wallet balance
            await updateDoc(ownProfileDoc.ref, {
                wallet: updatedOwnWallet
            });

            console.log(`Successfully deducted ${amount} from ${user.emailId}'s wallet and credited to your wallet.`);
            alert(`Successfully deducted ${amount} from ${user.emailId}'s wallet and credited to your wallet.`);
        } catch (error) {
            console.error("Error deducting funds:", error);
            alert("Failed to deduct funds. Please try again.");
        } finally {
            setAddAmount("");
            setConfirmAdd(false);
        }
    };





    const handleEnableUser = async (user, statusType) => {
        if (window.confirm("Are you sure you want to update the user's status?")) {
            console.log(user);
            try {
                // Get the current document snapshot
                const userDocRef = doc(db, "userProfile", user.id);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const currentStatus = userDocSnap.data().status;
                    // Toggle the status field
                    await updateDoc(userDocRef, { status: statusType });
                    console.log(`User status updated successfully for user ID: ${user.id}`);
                } else {
                    console.error(`User with ID ${user.id} does not exist.`);
                }
            } catch (error) {
                console.error("Error updating user status:", error);
            }
        } else {
            console.log("User status update canceled.");
        }
    };



    return (
        <div>
            {selectedUser && (
                <ModalComponent closeModal={closeModal}>
                    <div
                        className='addUserModal'
                        style={{
                            flexDirection: "column",
                            display: "flex",
                            width: windowWidth <= 768 ? "90%" : "90%",
                            maxWidth: "600px",
                            margin: "0 auto",
                            background: "white",
                            borderRadius: "8px",
                            padding: "20px"
                        }}
                    >
                        <p className='addUserModalHeading'>Edit User</p>
                        <div className="info-Inputs-user">
                            <label htmlFor="name">
                                Name
                                <input
                                    type="text"
                                    id="name"
                                    name="displayName"
                                    placeholder='Enter Name'
                                    value={selectedUser.displayName}
                                    onChange={(e) => handleInputChange(e, "displayName")}
                                />
                            </label>
                            <label htmlFor="username">
                                User Name
                                <input
                                    type="text"
                                    id="username"
                                    name="emailId"
                                    placeholder='Enter Username'
                                    value={selectedUser.emailId}
                                    onChange={(e) => handleInputChange(e, "emailId")}
                                />
                            </label>
                            <label htmlFor="adminEmail">
                                Admin Email
                                <input
                                    type="text"
                                    id="adminEmail"
                                    name="adminEmail"
                                    placeholder='Enter Admin Email'
                                    value={selectedUser.adminEmail}
                                    onChange={(e) => handleInputChange(e, "adminEmail")}
                                />
                            </label>
                            <label htmlFor="masterEmail">
                                Master Email
                                <input
                                    type="text"
                                    id="masterEmail"
                                    name="masterEmail"
                                    placeholder='Enter Master Email'
                                    value={selectedUser.masterEmail}
                                    onChange={(e) => handleInputChange(e, "masterEmail")}
                                />
                            </label>
                            <label htmlFor="phoneNumber">
                                Phone Number
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder='Enter Phone Number'
                                    value={selectedUser.phoneNumber}
                                    onChange={(e) => handleInputChange(e, "phoneNumber")}
                                />
                            </label>
                            <label htmlFor="type">
                                Type
                                <select
                                    id="type"
                                    name="type"
                                    value={selectedUser.type}
                                    onChange={(e) => handleInputChange(e, "type")}
                                >
                                    <option value="User">User</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Master">Master</option>
                                </select>
                            </label>
                            <label htmlFor="percentage">
                                Percentage
                                <input
                                    type="text"
                                    id="percentage"
                                    name="percentage"
                                    placeholder='Enter Percentage'
                                    value={selectedUser.percentage}
                                    onChange={(e) => handleInputChange(e, "percentage")}
                                />
                            </label>
                            <label htmlFor="balance">
                                Balance
                                <input
                                    type="text"
                                    id="balance"
                                    name="wallet"
                                    placeholder='Enter Balance'
                                    value={selectedUser.wallet}
                                    onChange={(e) => handleInputChange(e, "wallet")}
                                />
                            </label>
                            <label htmlFor="password">
                                Password
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder='Enter Password'
                                    value={selectedUser.password}
                                    onChange={(e) => handleInputChange(e, "password")}
                                />
                            </label>
                            <button className='addUserSubmit' onClick={handleEditUser}>Save Changes</button>
                        </div>
                    </div>
                </ModalComponent>
            )}
            <div className="table-wrapper">
                <table className="fl-table" style={{ borderCollapse: "separate" }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Type</th>
                            <th>%</th>
                            <th>Balance</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((user, index) => (
                            <tr key={index}>
                                <td style={{ border: "1px solid #000" }} data-label="Name">{user.displayName}</td>
                                <td style={{ border: "1px solid #000", cursor: "pointer" }} data-label="Username" onClick={() => handleUsernameClick(user.emailId, user.type)}>
                                    {user.emailId.replace("@gmail.com", "")}
                                </td>
                                <td style={{ border: "1px solid #000" }} data-label="Type">{user.type}</td>
                                <td style={{ border: "1px solid #000" }} data-label="%">{user.percentage}</td>
                                <td style={{ border: "1px solid #000" }} data-label="Balance">{user.wallet}</td>
                                <td style={{ border: "1px solid #000" }} >
                                    {user.status == "active" ? (
                                        <button className="toggleButton" onClick={() => handleEnableUser(user, "inactive")} style={{ background: "green", color: "white" }}>
                                            Enable
                                        </button>
                                    ) : (
                                        <button className="toggleButton" onClick={() => handleEnableUser(user, "active")} style={{ background: "red", color: "white" }}>
                                            Disable
                                        </button>
                                    )}
                                </td>
                                <td style={{ border: "1px solid #000" }} data-label="Action">
                                    <button className="viewEditUser" onClick={() => handleAddButtonClick(user)} style={{ background: "green", color: "white" }}>Add</button>
                                    <button className="viewEditUser" onClick={() => handleDeductButtonClick(user)} style={{ background: "red", color: "white" }}>Debit</button>
                                    <button className='viewEditUser' onClick={() => openModal(user)}>
                                        View/Edit
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selectedUserInfo && <AdditionalInnerUser username={selectedUserInfo.username} type={selectedUserInfo.type} />}
        </div>
    );
};

export default UserList;
