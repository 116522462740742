/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */

import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../utils/firebase';

const WithdrawReqMaster = () => {
    const [withdrawRequests, setWithdrawRequests] = useState([]);
    const [selectedActions, setSelectedActions] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const MasterEmail = auth.currentUser.email;

                // Fetch user profiles
                const userProfileQuerySnapshot = await getDocs(collection(db, "userProfile"));
                const userProfileDocs = userProfileQuerySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Filter user profiles by MasterEmail
                const filteredUserProfiles = userProfileDocs.filter(profile => profile.MasterEmail === MasterEmail);
                const filteredEmailIds = filteredUserProfiles.map(profile => profile.emailId);

                // Fetch withdrawal requests
                const querySnapshot = await getDocs(collection(db, "fund-withdraw-request"));
                const requests = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Filter requests by emailId
                const filteredRequests = requests.filter(request => filteredEmailIds.includes(request.emailId));

                setWithdrawRequests(filteredRequests);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Error fetching data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSelectChange = (id, action) => {
        setSelectedActions(prevState => ({ ...prevState, [id]: action }));
    };

    const handleConfirmAction = async (id) => {
        const action = selectedActions[id];
        if (!action) {
            console.error('No action selected');
            setError('No action selected');
            return;
        }

        try {
            const withdrawRequest = withdrawRequests.find(request => request.id === id);
            const withdrawRef = doc(db, "fund-withdraw-request", id);
            const { emailId, amount } = withdrawRequest;

            const userProfileQuerySnapshot = await getDocs(collection(db, "userProfile"));
            const userProfileDoc = userProfileQuerySnapshot.docs.find(doc => doc.data().emailId === emailId);

            if (!userProfileDoc) {
                console.error('User profile not found');
                setError('User profile not found');
                return;
            }

            const userProfileRef = doc(db, "userProfile", userProfileDoc.id);
            const userProfileData = userProfileDoc.data();

            const currentDate = new Date();
            const transactionEntry = {
                walletBefore: parseFloat(userProfileData.wallet) || 0,
                walletAfter: parseFloat(userProfileData.wallet),
                amount: parseFloat(amount),
                date: currentDate.toLocaleDateString(),
                time: currentDate.toLocaleTimeString(),
                status: action.toLowerCase()
            };

            if (action === "Approve") {
                await updateDoc(userProfileRef, {
                    fundWithdrawHistory: [...userProfileData.fundWithdrawHistory || [], transactionEntry]
                });

                await updateDoc(withdrawRef, { status: "approved" });
            } else if (action === "Decline") {
                transactionEntry.walletAfter += parseFloat(amount);

                await updateDoc(userProfileRef, {
                    wallet: transactionEntry.walletAfter,
                    fundWithdrawHistory: [...userProfileData.fundWithdrawHistory || [], transactionEntry]
                });

                await updateDoc(withdrawRef, { status: "declined" });
            }

            await deleteDoc(withdrawRef);

            setWithdrawRequests(withdrawRequests.filter(request => request.id !== id));
        } catch (error) {
            console.error('Error updating data:', error);
            setError('Error updating data');
        }
    };

    return (
        <div>
            {loading ? (
                <div>Loading data...</div>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div className="table-wrapper">
                    <table className="fl-table">
                        <thead>
                            <tr>
                                <th>Withdraw ID</th>
                                <th>Amount</th>
                                <th>Date & Time</th>
                                <th>Username</th>
                                <th>UID</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {withdrawRequests.map((request) => (
                                <tr key={request.id}>
                                    <td>{request.fundId}</td>
                                    <td>{request.amount}</td>
                                    <td>{request.date} || {request.time}</td>
                                    <td>{request.emailId}</td>
                                    <td>{request.uid}</td>
                                    <td className='tdFunReq-ButtonAlign'>
                                        <select onChange={(e) => handleSelectChange(request.id, e.target.value)}>
                                            <option value="">Select Action</option>
                                            <option value="Approve">Approve</option>
                                            <option value="Decline">Decline</option>
                                        </select>
                                        <button onClick={() => handleConfirmAction(request.id)}>Confirm</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default WithdrawReqMaster;
